import React, { Component } from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
import '../App.css';

const MyComponent = () => {
  // const location = useLocation()

  // React.useEffect(() => {
  //   // runs on location, i.e. route, change
  //   console.log('handle route change here', location)
  // }, [location])

  return (
    <div></div>
  )

}

export default MyComponent;