import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../App.css';

import logoInvert from '../Images/logo-invert.png';



class Footer extends Component {
  
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    visitWebsite() {

      window.location.href = "https://www.instagram.com/cooperheadart/";
      
    }

    
  
    render () {

      const footerYear = new Date().getUTCFullYear();

      return (
          
        <div className="FooterClass">

          <div className="footer">

            {/* <img src={logoInvert} className="header-desktop-logo" style={{padding: "0px", margin: "0px"}}></img> */}

            <div className="footer-text-container">
              <p className= "footer-text">© {footerYear} Cooperhead Art</p>
              <p className = "footer-text">Dylan Heatwole</p>
              <p className = "footer-text">Phone: <a className="phone-number" href="tel:5403151227">540.315.1227</a></p>
              <p className = "footer-text">Email: <a className="phone-number" href="mailto:cooperhead92@gmail.com">cooperhead92@gmail.com</a></p>
              <div className = "footer-text" onClick={(e) => {this.visitWebsite()}}>Instagram: <a className="phone-number">@cooperheadart</a></div>
            </div>
          </div>


        </div>
      );
    }
  }
  
  export default withRouter(Footer);

