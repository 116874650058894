import React, { Component, useEffect, useState } from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
import '../App.css';

import logo from '../Images/logo.png';
import hamburgermenu from '../Icons/bars-solid.svg';
import close from '../Icons/xmark-solid.svg';
import caret from '../Icons/caret-left-solid.svg';

import MyComponent from './MyComponent';


const Header = () => {

    const location = useLocation();
    const [ homeElem, setHomeElem ] = useState('');
    const [ homeCaretElem, setHomeCaretElem ] = useState('');
    const [ websiteElem, setWebsiteElem ] = useState('');
    const [ websiteCaretElem, setWebsiteCaretElem ] = useState('');
    const [ storyElem, setStoryElem ] = useState('');
    const [ storyCaretElem, setStoryCaretElem ] = useState('');
    const [ aboutElem, setAboutElem ] = useState('');
    const [ aboutCaretElem, setAboutCaretElem ] = useState('');
    const [ contactElem, setContactElem ] = useState('');
    const [ contactCaretElem, setContactCaretElem ] = useState('');


    React.useEffect(() => {

        let tempHome = document.getElementById("home");
        let tempWebsite = document.getElementById("website");
        let tempStory = document.getElementById("story");
        let tempAbout = document.getElementById("about");
        let tempContact = document.getElementById("contact");

        let tempHomeCaret = document.getElementById("homeCaret");
        let tempWebsiteCaret = document.getElementById("websiteCaret");
        let tempStoryCaret = document.getElementById("storyCaret");
        let tempAboutCaret = document.getElementById("aboutCaret");
        let tempContactCaret = document.getElementById("contactCaret");

      
    
      let currentURL = (location.pathname);

      if ( currentURL.endsWith("/") ) {
        colorLink(tempHome, tempHomeCaret, false);
      }
      else if ( currentURL.endsWith("/artwork") ) {
        colorLink(tempWebsite, tempWebsiteCaret, false);
      }
      else if ( currentURL.endsWith("/story") ) {
        colorLink(tempStory, tempStoryCaret, false);
      }
      else if ( currentURL.endsWith("/parties") ) {
        colorLink(tempAbout, tempAboutCaret, false);
      }
      else if ( currentURL.endsWith("/contact") ) {
        colorLink(tempContact, tempContactCaret, false);
      }
    
    }, [location]);

    function delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    }

    function toggleDrawer() {

      let target = document.getElementById("headerDrawer");
      let icon = document.getElementById("navIcon");


      if ( target.style.animationName == "drawerIn"  ) {
        target.style.animation = "drawerOut 0.4s ease-out forwards";
        icon.src = hamburgermenu;
      }
      else {
        target.style.animation = "drawerIn 0.1s ease-out forwards";
        icon.src = close;
      }

    }

    function closeDrawerAndReroute() {

      let target = document.getElementById("headerDrawer");
      let icon = document.getElementById("navIcon");

      delay(200).then(() => {
        target.style.animation = "drawerOut 0.1s ease-out forwards";
        icon.src = hamburgermenu;
      });

    }

    function colorLink(target, targetCaret, closeDrawer) {

      let homeElementGuarantee = document.getElementById("home");
      let websiteElementGuarantee = document.getElementById("website");
      let storyElementGuarantee = document.getElementById("story");
      let aboutElementGuarantee = document.getElementById("about");
      let contactElementGuarantee = document.getElementById("contact");
      
      homeElementGuarantee.style.color = "var(--color-white)";
      websiteElementGuarantee.style.color = "var(--color-white)";
      storyElementGuarantee.style.color = "var(--color-white)";
      aboutElementGuarantee.style.color = "var(--color-white)";
      contactElementGuarantee.style.color = "var(--color-white)";

      target.style.color = "var(--color-green)";

      colorCaret(targetCaret);

      if ( closeDrawer ) {
        closeDrawerAndReroute();
      }
    }

    function colorCaret(target) {

      let homeCaret = document.getElementById("homeCaret");
      let websiteCaret = document.getElementById("websiteCaret");
      let storyCaret = document.getElementById("storyCaret");
      let aboutCaret = document.getElementById("aboutCaret");
      let contactCaret = document.getElementById("contactCaret");

      homeCaret.style.opacity = "0";
      websiteCaret.style.opacity = "0";
      storyCaret.style.opacity = "0";
      aboutCaret.style.opacity = "0";
      contactCaret.style.opacity = "0";

      target.style.opacity = "1";

    }
  
      return (

        <div className="Header">

          <MyComponent/>

          <div id="headerDrawer" className="header-drawer">

            <div className="header-drawer-links">
              <div className="header-drawer-link-flex">
                <img id="homeCaret" src={caret} className="header-drawer-link-caret"></img>
                <span><Link id="home" to="/" className="header-drawer-link" onClick={() => colorLink(document.getElementById("home"), document.getElementById("homeCaret"), true)}>Home</Link></span>
              </div>

              <div className="header-drawer-link-flex">
                <img id="websiteCaret" src={caret} className="header-drawer-link-caret"></img>
                <span><Link id="website" to="/artwork" className="header-drawer-link" onClick={() => colorLink(document.getElementById("website"), document.getElementById("websiteCaret"), true)}>Art for Sale</Link></span>
              </div>

              <div className="header-drawer-link-flex">
                <img id="storyCaret" src={caret} className="header-drawer-link-caret"></img>
                <span><Link id="story" to="/story" className="header-drawer-link" onClick={() => colorLink(document.getElementById("story"), document.getElementById("storyCaret"), true)}>My Story</Link></span>
              </div>
              
              <div className="header-drawer-link-flex">
                <img id="aboutCaret" src={caret} className="header-drawer-link-caret"></img>
                <span><Link id="about" to="/parties" className="header-drawer-link" onClick={() => colorLink(document.getElementById("about"), document.getElementById("aboutCaret"), true)}>Party Services</Link></span>
              </div>

              <div className="header-drawer-link-flex">
                <img id="contactCaret" src={caret} className="header-drawer-link-caret"></img>
                <span><Link id="contact" to="/contact" className="header-drawer-link" onClick={() => colorLink(document.getElementById("contact"), document.getElementById("contactCaret"), true)}>Contact</Link></span>            
              </div>

            </div>

          </div>

          <div className="header">

            <div className="header-desktop">

              <div className="logo-container">
                <Link to="/" onClick={() => colorLink(document.getElementById("home"), document.getElementById("homeCaret"), false)}><img src={logo} className="header-desktop-logo"></img></Link>
                <Link to="/" onClick={() => colorLink(document.getElementById("home"), document.getElementById("homeCaret"), false)}><p className="title-logo">COOPERHEAD ART</p></Link>
              </div>

              <div className="header-desktop-links">
                <Link to="/" className="header-desktop-link">Home</Link>
                <Link to="/artwork" className="header-desktop-link">Art for Sale</Link>
                <Link to="/story" className="header-desktop-link">My Story</Link>
                <Link to="/parties" className="header-desktop-link">Party Services</Link>
                <Link to="/contact" className="header-desktop-link">Contact</Link>
              </div>

            </div>

            <div className="header-mobile">

            <div className="logo-container">
                <Link to="/" onClick={() => colorLink(document.getElementById("home"), document.getElementById("homeCaret"), false)}><img src={logo} className="header-desktop-logo"></img></Link>
                <Link to="/" onClick={() => colorLink(document.getElementById("home"), document.getElementById("homeCaret"), false)}><p className="title-logo">COOPERHEAD ART</p></Link>
              </div>

              <div id="test" className="header-mobile-clickbox" onClick={() => toggleDrawer()}>
                <img id="navIcon" src={hamburgermenu} className="header-mobile-icon"></img>
              </div>



            </div>

          </div>

          

        </div>
      );
    
  }
  
  export default Header;

