import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../App.css';

import banner from '../Images/banner.jpg';
import dylan from '../Images/dylan.jpg';
import party from '../Images/party.jpg';
import instagram from '../Images/instagram.png';


class Home extends Component {
  
    constructor(props) {
        super(props);

        this.photos = [];

    }

    componentDidMount() {

    }

    componentWillMount() {
      window.scrollTo(0, 0);

    }

    visitWebsite() {

      window.location.href = "https://www.instagram.com/cooperheadart/";
      
    }

  
    render () {

      return (
          
        <div className="Home">

          <div className="banner-container">
            <img className="banner" src={banner}></img>
            <div className="banner-shadow"></div>
            <div className="banner-title">
              <p className="title-small" style={{color: 'white'}}>WELCOME TO</p>
              <p className="test-title" style={{color: 'white'}} id="hero-id">COOPERHEAD ART</p>
              <p className="title-small" style={{color: 'white'}}>BY DYLAN HEATWOLE</p>
            </div>
              <div className="profile-picture-container" style={{display: "none"}}>
                <img className="profile-picture"src={dylan}></img>
            </div>
          </div> 

          {/* <div className="home-section">
            <p className="test-title">Hi, I'm Dylan</p>
            <p className="title-small">Welcome to my website. Come enjoy some art, plan a party to throw your own, or read more of my story.</p>
            <Link to="/story">
              <button className="hero-button">
                <span>SEE MY STORY</span>
              </button>
            </Link>
          </div> */}

          <div className="home-section-2">
            <div className="home-brandon-text">
              <p className="test-title">Hi, I'm Dylan</p>
              <p className="title-small">Welcome to my website. Come enjoy some art, plan a party to throw your own, or read more of my story.</p>
              <Link to="/story">
                <button className="hero-button">
                  <span>SEE MY STORY</span>
                </button>
              </Link>
            </div>
            <img className="home-brandon-image" src={dylan}></img>
          </div>     

          <div className="home-section">
              <p className="test-title">Artwork</p>
              <p className="title-small">New artwork and commissions available.</p>
            
            <div className="home-art-products-container">
              <div className="home-art-products-container-row">
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/home-1.jpeg"}></img>
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/home-2.jpeg"}></img>
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/home-3.jpeg"}></img>
              </div>
            </div>
            <Link to="/artwork">
                <button className="hero-button">
                  <span >VIEW ALL ARTWORK</span>
                </button>
              </Link>
          </div>

          <div className="home-section-2">
            <div className="home-brandon-text">
              <p className="test-title">Paint Parties</p>
              <p className="title-small">Sling some paint with your family, friends, coworkers, or neighbors. Perfect for all social events, small family fun, or parties. All supplies included, no cleanup needed.</p>
              <Link to="/parties">
                <button className="hero-button">
                  <span>BOOK A PARTY</span>
                </button>
              </Link>
            </div>
            <img className="home-brandon-image" src={party}></img>
          </div>

          <div className="home-centered">
            <div className="home-section">
              <img className="instagram-logo" src={instagram}></img>
              <div className="home-brandon-text" style={{textAlign: "center", alignItems: "center", width: "100%"}}>
                <p className="title-large" style={{color: "white", textDecoration: "underline"}} onClick={(e) => {this.visitWebsite()}} id="insta-handle-id">@cooperheadart</p>
                <p className="test-title" style={{color: "#919191", marginBottom: '0'}}>Follow me on Instagram</p>
                <Link onClick={(e) => {this.visitWebsite()}}>
                  <button id="follow-button" className="hero-button">
                    <span>FOLLOW</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
          

      
                  


          
        </div>
      );
    }
  }
  
  export default withRouter(Home);

