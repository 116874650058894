import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../App.css';

import placeholder from '../Images/placeholder.jpg';


class Parties extends Component {
  
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentWillMount() {
      window.scrollTo(0, 0);

    }

    
  
    render () {

      return (
          
        <div className="Page">

          <div className="page-banner-section">
            <div className="home-section">
              <p className="page-banner-title">Party Services</p>
            </div>
          </div>

          <div className="home-section">
              <p className="title-medium">Paint Party</p>
              <p className="title-small">Starting at $15/person, you can create your own beautiful masterpiece!</p> 
              
              <div className="trio-of-images">
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/party-1.jpeg"}></img>
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/party-2.jpeg"}></img>
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/party-3.jpeg"}></img>
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/party-4.jpeg"}></img>
              </div>
              
              <div>
                <p className="title-medium">Supplies included:</p>
                <ul className="title-small">
                  <li>Plastic drop sheet</li>
                  <li>Gloves</li>
                  <li>Pre-sprayed canvas with colors of your choice!</li>
                  <li>Paint for splattering </li>
                  <li>platter stick</li>
                  <li>Silver glitter top coating to make your art stand out!</li>
                </ul>
              </div>

              <div>
                <p className="title-medium">Additional products:</p>
                <ul className="title-small">
                  <li>Glow-in-the-dark/fluorescent/iridescent paint </li>
                  <li>Alternative color glitter spray </li>
                  <li>Acrylic paint and brushes to paint over your design </li>
                  <li>Simple stencils to create designs</li>
                </ul>
              </div>

              <p className="title-medium">Group discounts are available, beginning at groups of six.</p>
              <p className="title-small">Please contact me for more information.</p>
              <p className="title-small">Phone: <a className="phone-number" href="tel:5403151227">540.315.1227</a></p>
              <p className="title-small">Email: <a className="phone-number" href="mailto:cooperhead92@gmail.com">cooperhead92@gmail.com</a></p>
              <p className="title-small">Instagram: <a className="phone-number" onClick={(e) => {this.visitWebsite()}}>@cooperheadart</a></p>
          </div>


        </div>
      );
    }
  }
  
  export default withRouter(Parties);

