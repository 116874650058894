import React, { Component } from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';
import {Route, Link} from 'react-router-dom';
import './App.css';

import Header from './Components/Header';
import Home from './Components/Home';
import Artwork from './Components/Artwork';
import Story from './Components/Story';
import Contact from './Components/Contact';
import Parties from './Components/Parties';
import Footer from './Components/Footer';

class App extends Component {

  componentDidMount() {
    
  }

  render () {
    return (

      <div>
      
        <BrowserRouter>
          <div id="app" className="App">

            <Header/>

            <Switch>

              <Route exact path="/">
                <Home/>
              </Route>

              <Route exact path="/artwork">
                <Artwork/>
              </Route>

              <Route exact path="/story">
                <Story/>
              </Route>

              <Route exact path="/parties">
                <Parties/>
              </Route>

              <Route exact path="/contact">
                <Contact/>
              </Route>

            </Switch>

            <Footer/>

          </div>
        </BrowserRouter>
        
      </div>  
    );
  }
}

export default App;