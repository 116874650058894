import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../App.css';

import vid from '../Videos/movie.mp4';


class Artwork extends Component {
  
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentWillMount() {
      window.scrollTo(0, 0);

    }

    goToWebsite() {
      window.location.href = "https://wilsondisease.org";
    }

    
  
    render () {

      return (
          
        <div className="Page">

          <div className="page-banner-section">
            <div className="home-section">
              <p className="page-banner-title">My Story</p>
            </div>
          </div>

          <div className="home-section">
              <p className="title-medium">Intro</p>

              <p className="title-small">My name is Dylan Cooper Heatwole and I have a rare genetic condition called Wilson Disease. It prevents my body from processing copper properly and it causes an excess to build up. This disease can cause many debilitating issues such as extreme tremors, emotional regulation issues, cirrhosis, extreme fatigue, loss of coordination, difficulty swallowing, muscle stiffness and speech impairments.</p> 

              <div className="mobileVideoContainer">
                <video className="mobileVideo" muted="true" autoplay="true" loop="true" playsInline="true" webkit-playsinline>
                    <source src={vid} type="video/mp4" />
-               </video>
              </div>

              <p className="title-small">I woke up on 2/1/22 and my left hand started clenching and I couldn’t stop it. The tremors, as shown in the video clip, progressed rapidly to the point of me not being able to perform daily functions like feeding or dressing myself. Other symptoms quickly followed. It took about six months to get diagnosed through genetic testing. My most significant symptom was severe tremors; while they have calmed down significantly, I occasional deal with them. There is no cure for Wilson’s. Wilson Disease is deadly if left untreated but under proper care is very manageable. Currently, there are multiple clinical trials ongoing which I hope to join in the near future.</p> 
              <p className="title-medium">The Significance of the Penny</p>
              <p className="title-small">For the longest time I could not write or even sign my name so I came up with the idea to use a penny. Since it is copper in my head that is causing my tremors, a copperhead (penny) seemed to be the best solution. While I have improved to the point of being able to sign them, I still wanted to leave the penny as an homage to where it started. Life can sometimes be hilariously ironic, like my middle name being Cooper which sounds an awful lot like copper.</p>
              <p className="title-medium">Kindness</p>
              <p className="title-small">The world showed me so much kindness in the past year that I am determined to give it back to the world in every way possible. The greatest  example happened in mid June. My tremors were particularly severe that day and a homeless man walked up to me and emptied his pockets and said, “You look like you are in pain and this is everything I can give me to try to help.”</p>
              <p className="title-small">Since my hands started shaking I figured I would do something to give back to the world. I started making them in February of 2023 and giving them out to anyone who shows kindness to a stranger or could use a little joy in their life. While some are given out, some are sold. The paintings on display are for sale, please contact me directly for pricing on custom commissions.</p>
              <p className="title-small">Be better than you were before and if you stumble find the strength to move forward always! “…and if it’s shaky it’s even better because the whole premise of this is we are not going to win every time. We certainly can’t be perfect; we can’t control it but  we have an unstoppable will to more forward.” - Phil Stutz	</p>
              <p className="title-medium">Dylan "Cooperhead" Heatwole</p>
              <p className="title-small">For more information please visit <a className="phone-number" onClick={(e) => {this.goToWebsite()}}>wilsondisease.org</a></p>

          </div>


        </div>
      );
    }
  }
  
  export default withRouter(Artwork);

