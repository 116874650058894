import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../App.css';


class Artwork extends Component {
  
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentWillMount() {
      window.scrollTo(0, 0);

    }

    
  
    render () {

      return (
          
        <div className="Page">

          <div className="page-banner-section">
            <div className="home-section">
              <p className="page-banner-title">Artwork for Sale</p>
            </div>
          </div>

          <div className="home-section">
              <p className="title-medium">Art by Dylan Heatwole</p>
              <p className="title-small">New artwork and commissions available.</p>
            
            <div className="home-art-products-container">
              <div className="home-art-products-container-row">
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/art-1.jpeg"}></img>
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/art-2.jpeg"}></img>
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/art-3.jpeg"}></img>
              </div>
              <div className="home-art-products-container-row">
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/art-4.jpeg"}></img>
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/art-5.jpeg"}></img>
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/art-6.jpeg"}></img>
              </div>
              <div className="home-art-products-container-row">
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/art-7.jpeg"}></img>
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/art-8.jpeg"}></img>
                <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/art-9.jpeg"}></img>
              </div>
              <div className="home-art-products-container-row">
              <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/art-10.jpeg"}></img>
              <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/art-11.jpeg"}></img>
              <img className="home-art-product" src={"https://cooperheadbucket.s3.amazonaws.com/art-12.jpeg"}></img>
              </div>


            </div>
          </div>


        </div>
      );
    }
  }
  
  export default withRouter(Artwork);

