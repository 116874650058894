import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../App.css';

import dylan from '../Images/dylan.jpg';


class Contact extends Component {
  
    constructor(props) {
        super(props);
    }

    visitWebsite() {

      window.location.href = "https://www.instagram.com/cooperheadart/";
      
    }

    componentDidMount() {

    }

    componentWillMount() {
      window.scrollTo(0, 0);

    }

    
  
    render () {


      return (
          
        <div className="Page">

          <div className="page-banner-section">
            <div className="home-section">
              <p className="page-banner-title">Contact</p>
            </div>
          </div>

          <div className="home-section">
              <p className="title-medium">Dylan Heatwole</p>
              <p className="title-small">Phone: <a className="phone-number" href="tel:5403151227">540.315.1227</a></p>
              <p className="title-small">Email: <a className="phone-number" href="mailto:cooperhead92@gmail.com">cooperhead92@gmail.com</a></p>
              <p className="title-small">Instagram: <a className="phone-number" onClick={(e) => {this.visitWebsite()}}>@cooperheadart</a></p>        
          </div>


        </div>
      );
    }
  }
  
  export default withRouter(Contact);

